export default {
  methods: {
    sendJiraComment (installationId, message) {

      let jiraCommentDto = {
        'InstallationId': installationId,
        'Message': message
      };

      return this.axios.post('/Issue/SendJiraComment', jiraCommentDto);
    },
  }
}