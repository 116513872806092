<template>
  <div class="notifyGroupWizard">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div
        id="m_wizard"
        class="m-wizard m-wizard--2 m-wizard--success m-wizard--step-first"
      >
        <NotifyWizardNav
          v-if="!showCustom"
          :first-step="checkActive(0)"
          :second-step="checkActive(1) || checkActive(3)"
          :third-step="checkActive(2)"
        />

        <div class="m-wizard__form">
          <div class="m-portlet__body">
            <div class="row mb-3">
              <template v-if="checkActive(0) && roles && !showCustom">
                <div class="form-group mr-3 col-4">
                  <label for="roleSelect">{{ 'Select Role' }}</label>
                  <Multiselect
                    id="roles"
                    v-model="selectedRoles"
                    tag-placeholder="Not found"
                    placeholder="Role"
                    :options="roles"
                    select-label=""
                    :multiple="true"
                    :taggable="false"
                    :hide-selected="true"
                    :searchable="true"
                    :close-on-select="true"
                    class="mb-2"
                  />
                </div>
              </template>

              <template v-if="checkActive(0) && selectedRoles.length > 0 && !showCustom">
                <div class="form-group col-4">
                  <label for="recSelect">{{ 'Select Main Recipient' }}</label>
                  <select
                    id="recSelect"
                    v-model="mainRecipient"
                    class="mb-2 form-control"
                  >
                    <option
                      v-for="(roleRecipient, i) in roleRecipients"
                      :key="`role${i}`"
                      :value="roleRecipient"
                    >
                      {{ roleRecipient.firstname + " " + roleRecipient.surname }}
                    </option>
                  </select>
                </div>
              </template>
            </div>
            
            <NotifyGroupWizardStepZero />

            <NotifyGroupWizardCustom />
            
            <NotifyGroupWizardStepOne />

            <NotifyGroupWizardStepTwo />

            <!-- BUTTONS -->
            <div class="text-center">
              <button
                v-if="checkActive(0) && !showCustom"
                
                class="btn btn-sm btn-primary mr-2 float-left"
                @click="stepTowards('custom')"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="envelope"
                  style="vertical-align: middle;"
                />
                {{ $t('customMessage') }}
              </button>
              
              <template>
                <!-- BACK BUTTON -->
                <a
                  v-if="active == 1 || showCustom"
                  href="#"
                  class="btn btn-sm btn-metal mr-2"
                  @click.prevent="stepTowards('back')"
                ><font-awesome-icon
                  class="mr-2"
                  icon="arrow-left"
                  style="vertical-align: middle;"
                />{{ $t('back') }}</a>
                <!-- SEND BUTTON -->
                <a
                  v-if="!hasMissingLanguages && (checkActive(1) || showCustom)"
                  href="#"
                  class="btn btn-sm btn-primary mr-2"
                  @click.prevent="sendMessage(showCustom), showCustom ? stepTowards('last') : stepTowards('next')"
                >
                  <font-awesome-icon
                    class="mr-1"
                    icon="check"
                  />
                  <span>
                    {{ $t('send') }}
                  </span>
                </a>
                <button
                  v-else-if="checkActive(1)"
                  class="btn btn-sm btn-primary cursor-not-allowed mr-2"
                  disabled
                >
                  <font-awesome-icon
                    class="mr-1"
                    icon="check"
                  />
                  <span>
                    {{ $t('send') }}
                  </span>
                </button>
              </template>
              <div class="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <SweetModal
      ref="recipients"
      blocking
      title="Recipients"
      class="overflowHidden"
    >
      <ul class="list-group scrollable">
        <template
          v-for="(item, index) in roleRecipients"
        >
          <li
            :key="'li' + index"
            class="list-group-item list-group-item-action"
          >
            <font-awesome-icon
              class="mr-3 float-left"
              icon="user"
            />
            {{ `${item.firstname} ${item.surname}` }}
          </li>
        </template>
      </ul>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { urlParseMixin } from '@/mixins/urlParseMixin.js';
import { mapGetters } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';

import historyAdapter from '@/mixins/historyAdapter.js';
import jiraAdapter from '@/mixins/jiraAdapter.js';
export default {
  name: 'NotifyGroupWizard',
  components: {
    NotifyWizardNav: () => import('@/components/NotifyWizard/NotifyWizardNav.vue'),
    NotifyGroupWizardStepZero: () => import('@/components/NotifyWizard/NotifyGroupWizardStepZero.vue'),
    NotifyGroupWizardStepOne: () => import('@/components/NotifyWizard/NotifyGroupWizardStepOne.vue'),
    NotifyGroupWizardCustom: () => import('@/components/NotifyWizard/NotifyGroupWizardCustom.vue'),
    NotifyGroupWizardStepTwo: () => import('@/components/NotifyWizard/NotifyGroupWizardStepTwo.vue'),
    SweetModal,
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    urlParseMixin,
    jiraAdapter,
    historyAdapter
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    step: {
      type: String,
      required: false,
      default () {
        return '1';
      }
    }
  },
  data () {
    return {      
      roles: [],
      contacts: [],
      roleRecipients: null,
      mainRecipient: null,
      messageList: [],
      loading: true,
      languages: null,
      selectedTemplate: null,
      hasMissingLanguages: false,
      notifyTemplateList: null,
      active: 0,
      installationType: null,
      filter: null,
      noTranslationAvailable: false,
      languageAvailable: false,
      templatesAvailable: false,
      successfulSent: null,
      progress: 0,
      progressInterval: null,
      showCustom: false,
      message: null,
      selectedRoles: []
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
    filterByTitle () {
      if (!this.filter) {
        return this.notifyTemplateList;
      }
      return this.notifyTemplateList.filter(template => template.name.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
  watch: {
    selectedRoles () {
      this.roleRecipients = this.contacts.filter(x => this.selectedRoles.some(t => x.role && x.role.includes(t)));
      if (!this.mainRecipient || !this.roleRecipients.includes(this.mainRecipient)) {
        this.mainRecipient = this.roleRecipients[0];
      }
    }
  },
  mounted () {
    this.getAllTemplates();
    if (this.installationId) {
      this.getContacts();
    }
  },
  beforeDestroy () {
    clearInterval(this.progressInterval);
  },
  methods: {
    setLoading () {
      window.setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ endpoint }`);
    },
    checkActive (intToCheck) {
      return this.active == intToCheck;
    },
    setStep (step) {
      this.active = step;
    },
    async sendMessage (custom = false) {
      this.progressInterval = setInterval(() => {
        this.getProgress();
      }, 1000);

      var postObj = {
        primaryContact: this.mainRecipient,
        contacts: this.roleRecipients,
        isCustomMessage: custom,
        customMessage: this.message
      };
      this.axios.post(`/SmsGateway/SendGroupEmail?installationId=${this.installationId}&templateId=${ !custom ? this.selectedTemplate.id : -1 }`, postObj)
        .then(() => {
          if(this.progress == 100) {
            clearInterval(this.progressInterval);
            window.setTimeout(() => {
                  this.$router.push(`/installation/${ this.installationId }`)
                }, 2500);
          }
        })
        .catch(() => {
          clearInterval(this.progressInterval);
          this.successfulSent = true;
        });
    },
    getProgress () {
      this.axios.get(`/SmsGateway/GetGroupEmailProgress`)
        .then((response) => {
          this.progress = response.data;
          if(this.progress == 100) {
            clearInterval(this.progressInterval);
            this.successfulSent = true;
            window.setTimeout(() => {
                  this.$router.push(`/installation/${ this.installationId }`)
                }, 2500);
          }
        })
        .catch(() => {
          clearInterval(this.progressInterval);
        });
    },
    getAllTemplates () {
      this.axios.get('/Installation/GetInstallationTypeById?installationId=' + this.installationId)
        .then((response) => {
          this.installationType = response.data;
          this.axios.get('/SmsManagement/GetTemplatesByInstallationTypeId?installationTypeId=' + this.installationType.id)
            .then((response) => {
              if (response == null) {
                return;
              }
              if (response.data == null) {
                return;
              }
              if (response.data.length == null) {
                return;
              }
              if (response.data.length == 0) {
                return;
              }
              this.notifyTemplateList = response.data;
              this.templatesAvailable = true;
            });
        })
        .finally(() => {
          this.setLoading();
        });
    },
    getContacts () {
      this.axios.get(`/Contact/GetContacts/${this.installationId}`)
        .then((response) => {
          if(response == null) {
            return;
          }
          if(response.data == null) {
            return;
          }
          this.contacts = response.data.filter(x => !x.firstname.includes('Alturos') && !x.surname.includes('Alturos'));
          this.roles = [...new Set(this.contacts.map(x => x.role))];

          if (this.roles.includes('Operations')) {
            this.selectedRoles.push('Operations')
            this.roleRecipients = this.contacts.filter(x => x.role.includes('Operations'));
            this.mainRecipient = this.roleRecipients[0];
          }

          if (!this.contacts.some(x => x.languageCode && x.languageCode.length == 0)) {
            this.languageAvailable = true;
          }
        })
        .finally(() => {
          this.setLoading();
        });
    },
    stepTowards (direction) {
      if (direction === "custom") {
        this.showCustom = true;
        this.message = null;
      }
      else if (direction === "last") {
        this.active = 2;
        this.showCustom = false;
      }
      else {
        this.showCustom = false;
        this.message = null;
        if (direction === "next") {
          if (this.active < 2) {
            this.active++;
          }
        } else {
          if (this.active > 0) {
            this.active--;
          }
        }
      }
    },
    openRecipientsModal () {
      this.$refs.recipients.open();
    },
    // GET VALUE FROM SELECTED ELEMENT
    setCurrent (selectedTemplate, toNext = true) {
      this.selectedTemplate = selectedTemplate;
      this.loading = true;
      this.axios.post(`/SmsManagement/HasTemplateMissingContactsLanguages?templateId=${ selectedTemplate.id }`, [ this.mainRecipient ])
        .then((response) => {
          this.languages = response.data;
          this.hasMissingLanguages = this.languages.length > 0;
          if(!this.hasMissingLanguages) {
            this.axios.get(`/SmsManagement/GetMessageByLanguage?templateId=${ selectedTemplate.id }&primaryLanguage=${this.mainRecipient.languageCode[0]}&installationId=${ this.installationId }`)
              .then((response) => {
                this.messageList = [];
                this.messageList.push(response.data);
              })
              .finally(() => {
                this.loading = false;
            });
          }
        })
        .finally(() => {
          this.loading = false;
          if(toNext) {
            this.stepTowards('next');
          }
      });
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.form-control.btn.btn-secondary {
  text-align: left;
}
.loaderWrapper {
  margin: auto;
  text-align: center;
}
.m-loader {
  width: 100%;
  display: inline-block;
}
.m-loader::before {
  width: 5rem;
  height: 5rem;
  margin-left: -2.5rem;
}
.lead.m--font-info {
  padding-top: 5rem;
}
</style>
